import ShowCategories from "../components/Show/ShowCategories";
import ShowTopBar from "../components/Show/ShowTopBar";
import GridContentContainer from "../layout/GridContentContainer";

function CategoriesPage() {
  return (
    <GridContentContainer>
      <ShowTopBar title={"Alle Kategorier"} />
      <ShowCategories />
    </GridContentContainer>
  );
}

export default CategoriesPage;
