import React from "react";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

function SettingsButton({ onClick }) {
  return (
    <Link to="innstillinger">
      <Tooltip title="Innstillinger" placement="right">
        <IconButton
          onClick={onClick ? onClick : undefined}
          style={{ width: "2vw", minWidth: 50 }}
        >
          <SettingsIcon style={{ width: "80%", height: "80%" }} />
        </IconButton>
      </Tooltip>
    </Link>
  );
}

export default SettingsButton;
