import React from "react";
import { Grid } from "@mui/material";
import categories from "../../assets/data/categories.json";
import p from "../../assets/palette/cardPalette.json";
import { useNavigate } from "react-router-dom";
import FrontCard from "../ActivityCard/FrontCard";
import GridContentContainer from "../../layout/GridContentContainer";

function ShowHomePageOptions({ size }) {
  let navigate = useNavigate();

  return (
    <GridContentContainer>
      <Grid item xs={size}>
        <FrontCard
          name={"Alle kategorier"}
          image={categories[0].image}
          color={p.orangeGradient}
          onClick={() => navigate("kategorier")}
        />
      </Grid>
      <Grid item xs={size}>
        <FrontCard
          name={"Alle aktiviteter"}
          image={categories[1].image}
          color={p.newBlue}
          onClick={() => navigate("aktiviteter")}
        />
      </Grid>
      <Grid item xs={size}>
        <FrontCard
          name={"Alle kostholdstilbud"}
          image={categories[3].image}
          color={p.newDark}
          onClick={() => navigate("kosthold")}
        />
      </Grid>
    </GridContentContainer>
  );
}

export default ShowHomePageOptions;
