import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import categories from "../../assets/data/categories.json";
import { useNavigate } from "react-router-dom";
import FrontCard from "../ActivityCard/FrontCard";
import randomColor from "../../assets/palette/randomColor";
import GridContentContainer from "../../layout/GridContentContainer";

function ShowCategories() {
  let navigate = useNavigate();

  const [colors, setColors] = useState([]);

  useEffect(() => {
    const generatedColors = categories.map(() => randomColor());
    setColors(generatedColors);
  }, []);

  return (
    <GridContentContainer>
      {categories.map((category, index) => {
        return (
          <Grid item key={category.name}>
            <FrontCard
              name={category.name}
              image={category.image}
              color={colors.at(index)}
              onClick={() =>
                navigate(`/kategorier/${category.name.toLowerCase()}`)
              }
            />
          </Grid>
        );
      })}
    </GridContentContainer>
  );
}

export default ShowCategories;
