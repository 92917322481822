import ShowActivities from "../components/Show/ShowActivities";
import ShowTopBar from "../components/Show/ShowTopBar";
import GridContentContainer from "../layout/GridContentContainer";

function CategoryPage({ category }) {
  return (
    <GridContentContainer>
      <ShowTopBar title={category} />
      <ShowActivities category={category} />
    </GridContentContainer>
  );
}

export default CategoryPage;
