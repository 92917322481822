import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FrontCard from "../ActivityCard/FrontCard";
import randomColor from "../../assets/palette/randomColor";
import activities from "../../assets/data/activities.json";
import GridContentContainer from "../../layout/GridContentContainer";

function ShowActivities({ category = "" }) {
  let navigate = useNavigate();
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const generatedColors = activities.map(() => randomColor());
    setColors(generatedColors);
  }, []);

  return (
    <GridContentContainer>
      {category
        ? activities
            .filter((activity) => activity.category === category)
            .map((activity, index) => {
              return (
                <Grid key={activity.id}>
                  <FrontCard
                    name={activity.name}
                    image={activity.image}
                    color={colors.at(index)}
                    onClick={() =>
                      navigate(`/klubber/${activity.name.toLowerCase()}`)
                    }
                  />
                </Grid>
              );
            })
        : activities.map((activity, index) => {
            return (
              <FrontCard
                key={activity.id}
                name={activity.name}
                image={activity.image}
                color={colors.at(index)}
                onClick={() =>
                  navigate(`/klubber/${activity.name.toLowerCase()}`)
                }
              />
            );
          })}
    </GridContentContainer>
  );
}

export default ShowActivities;
