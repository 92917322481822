import React from "react";

function ContactTemplate({ contactName, clubName }) {
  return (
    <html
      style={{
        background: "white",
        padding: 15,
        borderRadius: 15,
      }}
    >
      <head>
        <title>Ta del i Aktivitetsbølgen!</title>
      </head>
      <body style={{ background: "white" }}>
        <p>Kjære {contactName},</p>

        <p>
          Vi håper denne e-posten finner deg i god behold. Vi har lagt merke til
          at {clubName} tilbyr ulike aktiviteter. Vi er glade for å introdusere
          Aktivitetsbølgen, en studentbedrift med mål om å tilby et bredere og
          mer oversiktelig aktivitetstilbud til folk flest!
        </p>

        <p>
          Hvis du er interessert i å bli med på Aktivitetsbølgen, ber vi deg om
          å gi oss følgende informasjon:
        </p>

        <ul>
          <li>Liste over tilbudte aktiviteter</li>
          <li>Aldersgrupper for hver aktivitet</li>
          <li>Kjønn for hver aktivitet</li>
        </ul>

        <p>
          Denne informasjonen vil hjelpe oss med å forstå dine tilbud bedre og
          sørge for at vi kan matche dem med potensielle deltakere på en
          effektiv måte. Vårt samarbeid vil gi en sømløs opplevelse både for din
          organisasjon og deltakerne.
        </p>

        <p>
          Hvis du har spørsmål eller trenger mer informasjon, må du gjerne
          kontakte oss på{" "}
          <a href="mailto: aktivitetsbolgensb@gmail.com">
            aktivitetsbolgensb@gmail.com
          </a>
        </p>

        <p>
          Vi ser frem til muligheten for å samarbeide med din organisasjon og
          hjelpe deg med å utvide ditt aktivitetstilbud.
        </p>
        <br />
        <p>
          Med vennlig hilsen,
          <br />
          Dineshan Panchalingam
          <br />
          <i>Prosjektleder</i>
        </p>
        <p></p>
        <p>
          <br />
          <b>Aktivitetsbølgen SB</b>
          <br />
          Mail:{" "}
          <a href="mailto: aktivitetsbolgensb@gmail.com">
            aktivitetsbolgensb@gmail.com
          </a>
          <br />
          Tlf: 401 04 718
        </p>
      </body>
    </html>
  );
}

export default ContactTemplate;
