import { useState } from "react";
import {
  Grid,
  Tabs,
  Tab,
  //ToggleButtonGroup,
  //ToggleButton,
} from "@mui/material";
//import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
//import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

function ShowSubBar({ setShowActivities }) {
  const [value, setValue] = useState(0);

  /* 
  const [alignment, setAlignment] = useState("card");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  */

  const handleChange = (event, newValue) => {
    setValue(newValue);
    newValue === 0 ? setShowActivities(true) : setShowActivities(false);
  };

  return (
    <Grid container alignItems="center" direction="row" position="sticky">
      <Tabs value={value} onChange={handleChange} sx={{ margin: "auto" }}>
        <Tab disableRipple label="Aktiviteter" />
        <Tab disableRipple label="Kostholdstilbud" />
      </Tabs>
      {/*
      <ToggleButtonGroup
        style={{ position: "absolute", right: 0 }}
        value={alignment}
        onChange={handleAlignment}
        exclusive
      >
        <ToggleButton value="card">
          <ViewCarouselIcon />
        </ToggleButton>
        <ToggleButton value="list">
          <FormatListBulletedIcon />
        </ToggleButton>
      </ToggleButtonGroup>
       */}
    </Grid>
  );
}

export default ShowSubBar;
