import React from "react";

import ShowTopBar from "../components/Show/ShowTopBar";
import GridContentContainer from "../layout/GridContentContainer";

function ProfilePage() {
  return (
    <GridContentContainer>
      <ShowTopBar title="Min Profil" />
    </GridContentContainer>
  );
}

export default ProfilePage;
