import ShowClubs from "../components/Show/ShowClubs";
import ShowTopBar from "../components/Show/ShowTopBar";
import GridContentContainer from "../layout/GridContentContainer";

function ClubsPage({ activity }) {
  return (
    <GridContentContainer>
      <ShowTopBar
        title={
          activity
            ? `Tilbydere av ${activity.toLowerCase()}`
            : "Alle tilgjengelige klubber"
        }
      />
      <ShowClubs activity={activity} />
    </GridContentContainer>
  );
}

export default ClubsPage;
