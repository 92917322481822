import React, { useEffect, useState } from "react";
import ReactCardFlip from "react-card-flip";
import BackCard from "./BackCard";
import FrontCard from "./FrontCard";
import randomColor from "../../assets/palette/randomColor";

function FlipCard({ activity, club }) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [color, setColor] = useState("");

  const toggleIsFlipped = () => {
    isFlipped ? setIsFlipped(false) : setIsFlipped(true);
  };

  useEffect(() => {
    setColor(randomColor);
  }, []);

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
      <FrontCard
        color={color}
        name={activity ? activity.name : club.name}
        image={activity ? activity.image : club.emblem}
        onClick={toggleIsFlipped}
        clubSize
      />
      <BackCard
        activity={activity}
        club={club}
        color={color}
        onClick={toggleIsFlipped}
      />
    </ReactCardFlip>
  );
}

export default FlipCard;
