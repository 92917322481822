import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import { useState } from "react";
import emblemPlaceholder from "../../assets/images/badge-placeholder.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function FrontCard({ name, image, color, onClick }) {
  const { height, width } = useWindowDimensions();
  const [activeImage, setActiveImage] = useState(image);

  const SMALL_WIDTH = 750;
  const MACBOOK_WIDTH = 1440;

  const onError = () => {
    setActiveImage(emblemPlaceholder);
  };

  return (
    <Card
      sx={{
        background: color,

        height:
          width <= SMALL_WIDTH
            ? "34vh"
            : width < MACBOOK_WIDTH
            ? "28vh"
            : width > 3000 && height > 2000
            ? "39vh"
            : "42vh",
        width:
          width <= SMALL_WIDTH
            ? "48vw"
            : width < MACBOOK_WIDTH
            ? "20vw"
            : "15vw",

        marginTop:
          width > MACBOOK_WIDTH ? 8 : width > 3000 && height > 2000 && 12,
      }}
    >
      <CardActionArea
        onClick={onClick}
        style={{ minHeight: "100%", minWidth: "100%" }}
      >
        <CardMedia
          id={image}
          component="img"
          image={activeImage}
          alt={name}
          onError={onError}
          style={{
            objectFit: "scale-down",
            maxWidth: "90%",
            maxHeight: "90%",
            minWidth: "90%",
            margin: "auto",
            overflow: "hidden",
          }}
        />

        <CardContent>
          <Typography
            style={{
              color: "white",
              fontWeight: "bold",
              position: "absolute",
              bottom: 10,
              fontSize:
                name.length > 15 ? 15 : width > 3000 && height > 2000 ? 30 : 17,
            }}
          >
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default FrontCard;
