import React, { createContext, useContext, useState, useEffect } from "react";

const FavContext = createContext();

export function useFav() {
  return useContext(FavContext);
}

export function FavProvider({ children }) {
  const [favorites, setFavorites] = useState([]);

  // Load favorites from localStorage on initial render
  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setFavorites(storedFavorites);
  }, []); // The empty dependency array ensures that this only runs on the initial render

  // Update localStorage whenever favorites change
  useEffect(() => {
    localStorage.setItem("favorites", JSON.stringify(favorites));
  }, [favorites]);

  const addToFavorites = (item) => {
    if (!isFavorite(item)) {
      setFavorites((prevFavorites) => [...prevFavorites, item]);
    }
  };

  const removeFromFavorites = (item) => {
    setFavorites((prevFavorites) =>
      prevFavorites.filter(
        (favItem) => JSON.stringify(favItem) !== JSON.stringify(item)
      )
    );
  };

  const isFavorite = (item) => {
    return favorites.some(
      (favItem) => JSON.stringify(favItem) === JSON.stringify(item)
    );
  };

  return (
    <FavContext.Provider
      value={{
        favorites,
        addToFavorites,
        removeFromFavorites,
        isFavorite,
      }}
    >
      {children}
    </FavContext.Provider>
  );
}
