import React from "react";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

function ProfileButton({ onClick }) {
  return (
    <Link to="profil">
      <Tooltip title="Min profil" placement="right">
        <IconButton
          onClick={onClick ? onClick : undefined}
          style={{ width: "2vw", minWidth: 50 }}
        >
          <PersonIcon style={{ width: "80%", height: "80%" }} />
        </IconButton>
      </Tooltip>
    </Link>
  );
}

export default ProfileButton;
