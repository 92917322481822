import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

import FooterButton from "../Buttons/FooterButton";

import { useAuth } from "../../context/AuthContext";

function LoginDialog() {
  const [open, setOpen] = useState(false);
  const { isAuthenticated, login, logout } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");

  const handleCloseSnackbar = () => {
    loginError ? setLoginError(false) : setLoginSuccess(false);
  };

  const adminUserName = process.env.REACT_APP_ADMIN_USERNAME;
  const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      if (adminPassword === password && adminUserName === userName) {
        login();
        setPassword("");
        setLoginSuccess(true);
        setLoginMessage("Vellykket innlogging.");
        handleClose();
      } else {
        setLoginError(true);
        setLoginMessage("Feil brukernavn eller passord!");
      }
      setIsLoading(false);
    }, 500);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <FooterButton
        label={isAuthenticated ? "Logg ut" : "Logg inn"}
        onClick={isAuthenticated ? () => logout() : handleClickOpen}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Logg inn</DialogTitle>
        <form onSubmit={(e) => handleLogin(e)}>
          <DialogContent>
            <TextField
              required
              fullWidth
              label="Brukernavn"
              name="username"
              value={userName}
              onChange={handleUserNameChange}
              size="small"
              style={{ marginBottom: 15 }}
            />
            <TextField
              required
              fullWidth
              label="Passord"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              size="small"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Lukk</Button>
            <Button variant="contained" type="submit" disabled={isLoading}>
              Logg inn
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={loginError || loginSuccess}
        onClose={handleCloseSnackbar}
        key={3}
      >
        <Alert severity={loginError ? "error" : loginSuccess ? "success" : ""}>
          {loginMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default LoginDialog;
