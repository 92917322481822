import React, { useRef, useState } from "react";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import emailjs from "@emailjs/browser";
import FooterButton from "../Buttons/FooterButton";

function ContactDialog() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [mailSuccess, setMailSuccess] = useState(false);
  const form = useRef();

  const {
    REACT_APP_EMAIL_SERVICE_ID,
    REACT_APP_EMAIL_CONTACT_TEMPLATE_ID,
    REACT_APP_EMAIL_PUBLIC_KEY,
  } = process.env;

  const handleCloseSnackbar = () => {
    mailError ? setMailError(false) : setMailSuccess(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleMailError = () => {
    setMailError(true);
    console.error("Error: Feil mailadresse. Fikk ikke sendt mail.");
  };

  const handleSendMail = (e) => {
    // Prevent normal form submit behaviour
    e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm(
        REACT_APP_EMAIL_SERVICE_ID,
        REACT_APP_EMAIL_CONTACT_TEMPLATE_ID,
        form.current,
        REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(function (result) {
        setMailSuccess(true);
        setIsSending(false);
        setOpen(false);
      })
      .catch(function (error) {
        error.status === 412 ? handleMailError() : alert("Ukjent feil");
        setIsSending(false);
      });
  };

  return (
    <>
      <FooterButton label="Kontakt oss" onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose}>
        <form ref={form} onSubmit={handleSendMail}>
          <DialogTitle>Kontakt oss</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Vennligst fyll ut feltene under for å sende oss en mail.
            </DialogContentText>
            <TextField
              sx={{ marginTop: 2 }}
              autoFocus
              id="userName"
              label="Navn"
              name="userName"
              value={name}
              fullWidth
              required
              onChange={handleNameChange}
            />
            <TextField
              sx={{ marginTop: 2 }}
              autoFocus
              id="userEmail"
              label="Email adresse"
              name="userEmail"
              value={email}
              fullWidth
              required
              onChange={handleEmailChange}
            />
            <TextField
              sx={{ marginTop: 2 }}
              id="userMessage"
              name="userMessage"
              multiline
              label="Skriv her"
              value={message}
              rows={4}
              fullWidth
              required
              onChange={handleMessageChange}
            />
            <DialogContentText sx={{ marginTop: 2 }}>
              Eller ring{" "}
              <a
                style={{ textDecoration: "none", color: "black" }}
                href="tel:40104718"
              >
                +47 401 04 718
              </a>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Avbryt</Button>
            <Button type="submit">
              {mailSuccess
                ? "Mail sendt"
                : mailError
                ? "Kunne ikke sende"
                : "Send mail"}
              {isSending ? (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : mailError ? (
                <ErrorOutlineIcon sx={{ ml: 1 }} />
              ) : (
                mailSuccess && <CheckCircleOutlineIcon sx={{ ml: 1 }} />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={mailError || mailSuccess}
        onClose={handleCloseSnackbar}
        key={1}
      >
        <Alert severity={mailError ? "error" : mailSuccess ? "success" : ""}>
          {mailError
            ? "Fikk ikke sendt mail. Feil mailadresse."
            : mailSuccess
            ? "Mail sendt!"
            : ""}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ContactDialog;
