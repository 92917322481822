import React from "react";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

function ContactButton({ showOption, toggleShowOption }) {
  return (
    <Fab
      variant="extended"
      size="medium"
      color={showOption === "contact" ? "success" : "primary"}
      onClick={() => toggleShowOption("contact")}
    >
      {showOption === "contact" ? (
        <CloseIcon sx={{ mr: 1 }} />
      ) : (
        <AddIcon sx={{ mr: 1 }} />
      )}
      Kontakt ny klubb
    </Fab>
  );
}

export default ContactButton;
