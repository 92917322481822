import React from "react";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";

function ClubsButton({ onClick }) {
  return (
    <Link to="klubber">
      <Tooltip title="Klubber" placement="right">
        <IconButton
          onClick={onClick ? onClick : undefined}
          style={{ width: "2vw", minWidth: 50 }}
        >
          <GroupsIcon style={{ width: "80%", height: "80%" }} />
        </IconButton>
      </Tooltip>
    </Link>
  );
}

export default ClubsButton;
