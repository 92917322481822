import React from "react";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

function AdminButton({ onClick }) {
  return (
    <Link to="admin">
      <Tooltip title="Admin" placement="right">
        <IconButton
          onClick={onClick ? onClick : undefined}
          style={{ width: "2vw", minWidth: 50 }}
        >
          <AdminPanelSettingsIcon style={{ width: "80%", height: "80%" }} />
        </IconButton>
      </Tooltip>
    </Link>
  );
}

export default AdminButton;
