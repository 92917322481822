import React from "react";
import ShowTopBar from "../components/Show/ShowTopBar";
import GridContentContainer from "../layout/GridContentContainer";

function SettingsPage() {
  return (
    <GridContentContainer>
      <ShowTopBar title={"Innstillinger"} />
    </GridContentContainer>
  );
}

export default SettingsPage;
