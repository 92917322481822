import React, { useState } from "react";

import { Grid } from "@mui/material";

import ShowOption from "./ShowOption";
import {
  NewActivityButton,
  NewCategoryButton,
  NewClubButton,
  NewKostholdButton,
  ContactButton,
} from "../../components/Buttons";

function ShowAddOptions() {
  const [showOption, setShowOption] = useState("");

  const toggleShowOption = (option) => {
    showOption === option ? setShowOption("") : setShowOption(option);
  };

  return (
    <Grid container direction="column" alignItems="flex-start" rowGap={5}>
      {showOption === "activity" ? (
        <NewActivityButton
          showOption={showOption}
          toggleShowOption={toggleShowOption}
        />
      ) : showOption === "category" ? (
        <NewCategoryButton
          showOption={showOption}
          toggleShowOption={toggleShowOption}
        />
      ) : showOption === "club" ? (
        <NewClubButton
          showOption={showOption}
          toggleShowOption={toggleShowOption}
        />
      ) : showOption === "kosthold" ? (
        <NewKostholdButton
          showOption={showOption}
          toggleShowOption={toggleShowOption}
        />
      ) : showOption === "contact" ? (
        <ContactButton
          showOption={showOption}
          toggleShowOption={toggleShowOption}
        />
      ) : (
        <>
          <NewActivityButton
            showOption={showOption}
            toggleShowOption={toggleShowOption}
          />
          <NewCategoryButton
            showOption={showOption}
            toggleShowOption={toggleShowOption}
          />
          <NewClubButton
            showOption={showOption}
            toggleShowOption={toggleShowOption}
          />
          <NewKostholdButton
            showOption={showOption}
            toggleShowOption={toggleShowOption}
          />
          <ContactButton
            showOption={showOption}
            toggleShowOption={toggleShowOption}
          />
        </>
      )}
      <ShowOption showOption={showOption} />
    </Grid>
  );
}

export default ShowAddOptions;
