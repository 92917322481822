import { Grid } from "@mui/material";
import FlipCard from "../components/ActivityCard/FlipCard";
import ShowTopBar from "../components/Show/ShowTopBar";
import { useFav } from "../context/FavContext";
import GridContentContainer from "../layout/GridContentContainer";

function FavoritesPage(/*{userId}*/) {
  const { favorites } = useFav();

  return (
    <GridContentContainer>
      <ShowTopBar title="Mine Favoritter" />

      {favorites.map((favorite) => {
        return (
          <Grid item key={favorite.id}>
            <FlipCard activity={favorite.activity} club={favorite.club} />
          </Grid>
        );
      })}
    </GridContentContainer>
  );
}

export default FavoritesPage;
