import React, { useEffect } from "react";
import ShowTopBar from "../components/Show/ShowTopBar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import ShowAddOptions from "../components/Show/ShowAddOptions";
import GridContentContainer from "../layout/GridContentContainer";

function AdminPage() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  });
  return (
    <GridContentContainer>
      <ShowTopBar title={"Admin"} />
      <ShowAddOptions />
    </GridContentContainer>
  );
}

export default AdminPage;
