import React from "react";
import { Link } from "react-router-dom";
import { IconButton, Tooltip, Badge } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useFav } from "../../../context/FavContext";

function FavoritesButton({ onClick }) {
  const { favorites } = useFav();

  return (
    <Link to="favoritter">
      <Tooltip title="Favoritter" placement="right">
        <IconButton
          onClick={onClick ? onClick : undefined}
          style={{ width: "2vw", minWidth: 50 }}
        >
          <Badge badgeContent={favorites.length} color="error">
            <FavoriteIcon
              style={{
                width: "1.6vw",
                height: "1.6vw",
                minWidth: 40,
                minHeight: 40,
              }}
            />
          </Badge>
        </IconButton>
      </Tooltip>
    </Link>
  );
}

export default FavoritesButton;
