import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import FooterButton from "../Buttons/FooterButton";

function AboutDialog() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <FooterButton label="Om oss" onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Om Aktivitetsbølgen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Aktivitetsbølgen SB er en nyoppstartet studentbedrift av
            folkehelsestudenter med mål om å gjøre aktivitetstilbud i nærområdet
            lettere tilgjengelig for alle!
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Lukk</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AboutDialog;
