import ShowHomePageOptions from "../components/Show/ShowHomePageOptions";
import ShowTopBar from "../components/Show/ShowTopBar";
import useWindowDimensions from "../hooks/useWindowDimensions";
import ab from "../assets/images/aktivitetsbølgen.png";
import GridContentContainer from "../layout/GridContentContainer";

function HomePage() {
  const { width } = useWindowDimensions();
  const SMALL_WIDTH = 750;

  return (
    <GridContentContainer>
      <ShowTopBar title={"Velkommen til Aktivitetsbølgen!"} bottomMargin={3} />
      <div
        style={{
          width: width <= SMALL_WIDTH ? "35vw" : "10vw",
        }}
      >
        <img src={ab} alt="logo" style={{ width: "100%", heigth: "auto" }} />
      </div>

      <ShowHomePageOptions />
    </GridContentContainer>
  );
}

export default HomePage;
