import React, { useRef, useState } from "react";
import {
  Fab,
  TextField,
  Typography,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import PreviewIcon from "@mui/icons-material/Preview";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import ContactTemplate from "../assets/templates/ContactTemplate";
import emailjs from "@emailjs/browser";

function SendEmailForm() {
  const [contactEmail, setContactEmail] = useState("");
  const [clubName, setClubName] = useState("");
  const [contactName, setContactName] = useState("");
  const [showMail, setShowMail] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [mailSuccess, setMailSuccess] = useState(false);
  const form = useRef();

  const {
    REACT_APP_EMAIL_SERVICE_ID,
    REACT_APP_EMAIL_TEMPLATE_ID,
    REACT_APP_EMAIL_PUBLIC_KEY,
  } = process.env;

  const handleCloseSnackbar = () => {
    mailError ? setMailError(false) : setMailSuccess(false);
  };

  const handleContactEmailChange = (e) => {
    setContactEmail(e.target.value);
  };

  const handleClubNameChange = (e) => {
    setClubName(e.target.value);
  };

  const handleContactNameChange = (e) => {
    setContactName(e.target.value);
  };

  const toggleShowMail = () => {
    showMail ? setShowMail(false) : setShowMail(true);
  };

  const handleMailError = () => {
    setMailError(true);
    console.error("Error: Feil mailadresse. Fikk ikke sendt mail.");
  };

  const handleSendMail = (e) => {
    // Prevent normal form submit behaviour
    e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm(
        REACT_APP_EMAIL_SERVICE_ID,
        REACT_APP_EMAIL_TEMPLATE_ID,
        form.current,
        REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(function (result) {
        setMailSuccess(true);
        setIsSending(false);
      })
      .catch(function (error) {
        error.status === 412 ? handleMailError() : alert("Ukjent feil");
        setIsSending(false);
      });
  };

  return (
    <Grid container direction="row" justifyContent="space-between">
      <form onSubmit={handleSendMail} ref={form}>
        <Grid
          container
          spacing={2}
          rowGap={1}
          width="40vw"
          maxWidth="500px"
          maxHeight={10}
        >
          <Grid item xs={12}>
            <Typography variant="h5">Kontakt ny klubb</Typography>
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              variant="outlined"
              fullWidth
              label="Kontaktperson"
              name="contactName"
              value={contactName}
              onChange={handleContactNameChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              variant="outlined"
              fullWidth
              label="Klubbnavn"
              name="clubName"
              value={clubName}
              onChange={handleClubNameChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              variant="outlined"
              fullWidth
              label="Email adresse"
              name="contactEmail"
              value={contactEmail}
              onChange={handleContactEmailChange}
            />
          </Grid>

          <Grid item xs={6}>
            <Fab variant="extended" color="primary" onClick={toggleShowMail}>
              Forhåndsvis mail
              <PreviewIcon sx={{ ml: 1 }} />
            </Fab>
          </Grid>
          <Grid item xs={6}>
            <Fab
              type="submit"
              variant="extended"
              color={mailSuccess ? "success" : mailError ? "error" : "primary"}
              disabled={isSending ? true : false}
              sx={{ minWidth: "100%" }}
            >
              {mailSuccess
                ? "Mail sendt"
                : mailError
                ? "Kunne ikke sende"
                : "Send mail"}
              {isSending ? (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : mailError ? (
                <ErrorOutlineIcon sx={{ ml: 1 }} />
              ) : mailSuccess ? (
                <CheckCircleOutlineIcon sx={{ ml: 1 }} />
              ) : (
                <SendIcon sx={{ ml: 1 }} />
              )}
            </Fab>
          </Grid>
        </Grid>
      </form>

      <Grid
        container
        spacing={2}
        rowGap={1}
        width="50vw"
        maxWidth="40vw"
        maxHeight="30vh"
      >
        {showMail ? (
          <Grid item fullWidth marginTop={-11}>
            <ContactTemplate contactName={contactName} clubName={clubName} />
          </Grid>
        ) : null}
      </Grid>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={mailError || mailSuccess}
        onClose={handleCloseSnackbar}
        key={1}
      >
        <Alert severity={mailError ? "error" : mailSuccess ? "success" : ""}>
          {mailError
            ? "Fikk ikke sendt mail. Feil mailadresse."
            : mailSuccess
            ? "Mail sendt!"
            : ""}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default SendEmailForm;
