import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  // Initialize isAuthenticated from sessionStorage or default to false
  const initialIsAuthenticated =
    sessionStorage.getItem("isAuthenticated") === "true";
  const [isAuthenticated, setIsAuthenticated] = useState(
    initialIsAuthenticated
  );

  // Define login and logout functions that update sessionStorage
  const login = () => {
    setIsAuthenticated(true);
    sessionStorage.setItem("isAuthenticated", "true");
  };

  const logout = () => {
    setIsAuthenticated(false);
    sessionStorage.setItem("isAuthenticated", "false");
  };

  useEffect(() => {
    // This effect listens for changes to isAuthenticated and updates sessionStorage
    sessionStorage.setItem("isAuthenticated", isAuthenticated.toString());
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
