import { useState } from "react";
import ShowClubActivities from "../components/Show/ShowClubActivities";
import ShowKosthold from "../components/Show/ShowKosthold";
import ShowSubBar from "../components/Show/ShowSubBar";
import ShowTopBar from "../components/Show/ShowTopBar";
import GridContentContainer from "../layout/GridContentContainer";

function ClubPage({ club }) {
  const [showActivities, setShowActivities] = useState(true);

  return (
    <GridContentContainer>
      <ShowTopBar title={club.name} bottomMargin={4} />
      <ShowSubBar setShowActivities={setShowActivities} />
      {showActivities ? (
        <ShowClubActivities club={club} />
      ) : (
        <ShowKosthold club={club} />
      )}
    </GridContentContainer>
  );
}

export default ClubPage;
