import React from "react";
import { Grid } from "@mui/material";
import {
  ActivityForm,
  ClubForm,
  CategoryForm,
  KostholdForm,
} from "../../forms";
import SendEmailForm from "../../forms/SendEmailForm";

function ShowOption({ showOption }) {
  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      rowGap={5}
      marginTop={2}
    >
      {showOption === "activity" ? (
        <ActivityForm />
      ) : showOption === "club" ? (
        <ClubForm />
      ) : showOption === "category" ? (
        <CategoryForm />
      ) : showOption === "kosthold" ? (
        <KostholdForm />
      ) : showOption === "contact" ? (
        <SendEmailForm />
      ) : null}
    </Grid>
  );
}

export default ShowOption;
