import { Grid, Typography } from "@mui/material";

function ShowKosthold({ club }) {
  return (
    <Grid container justifyContent="center" spacing={3}>
      <Typography marginTop={2} color="grey">
        {club.name} har ingen oppførte kostholdstilbud
      </Typography>
    </Grid>
  );
}

export default ShowKosthold;
