import { ButtonGroup } from "@mui/material";
import React from "react";
import AboutDialog from "../components/Dialogs/AboutDialog";
import ContactDialog from "../components/Dialogs/ContactDialog";
import LoginDialog from "../components/Dialogs/LoginDialog";
import useWindowDimensions from "../hooks/useWindowDimensions";

function Footer() {
  const { width } = useWindowDimensions();
  const SMALL_WIDTH = 750;

  return (
    <ButtonGroup
      style={{
        width: width <= SMALL_WIDTH ? "93vw" : "88vw",
        marginLeft: width <= SMALL_WIDTH ? "3vw" : "8.7vw",
        justifyContent: "center",
      }}
    >
      <AboutDialog /> <ContactDialog /> <LoginDialog />
    </ButtonGroup>
  );
}

export default Footer;
