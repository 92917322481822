import React, { useState } from "react";
import {
  TextField,
  Grid,
  Fab,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";

import { CloudUpload } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";

import clubs from "../assets/data/clubs.json";
import activities from "../assets/data/categories.json";
import SelectMultiple from "../components/Input/SelectMultiple";

function ActivityForm() {
  const [activityInfo, setActivityInfo] = useState({
    activityName: "",
    contactPerson: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [activitySubmitMessage, setActivitySubmitMessage] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const handleCloseSnackbar = () => {
    submitError ? setSubmitError(false) : setSubmitSuccess(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setActivityInfo({ ...activityInfo, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setActivityInfo({ ...activityInfo, clubEmblem: file });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setActivitySubmitMessage(
      "Kunne ikke legge til ny aktivitet. Funksjonalitet er i produksjon."
    );
    setTimeout(
      () => {
        setSubmitError(true);
        setIsLoading(false);
      },

      1000
    );
    //setActivitySubmitMessage("La til ny aktkvitet:", activityInfo.activityName);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} rowGap={1} width="40vw" maxWidth="500px">
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Aktivitetsnavn"
            name="activityName"
            value={activityInfo.clubName}
            onChange={handleInputChange}
          />
        </Grid>
        {/* 
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Kontaktperson"
            name="contactPerson"
            value={activityInfo.contactPerson}
            onChange={handleInputChange}
          />
        </Grid>*/}

        <Grid item xs={6}>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="clubEmblem"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="clubEmblem">
            <Fab variant="extended" component="span" color="primary">
              <CloudUpload sx={{ mr: 1 }} />
              Last opp logo
            </Fab>
          </label>
        </Grid>
        <Grid item xs={6}>
          <Fab
            type="submit"
            variant="extended"
            color={
              submitSuccess ? "success" : submitError ? "error" : "primary"
            }
            disabled={isLoading}
            sx={{ minWidth: "100%" }}
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            ) : submitError ? (
              <ErrorOutlineIcon sx={{ mr: 1 }} />
            ) : submitSuccess ? (
              <CheckCircleOutlineIcon sx={{ mr: 1 }} />
            ) : (
              <AddIcon sx={{ mr: 1 }} />
            )}
            {submitSuccess
              ? `${activityInfo.activityName} registrert`
              : submitError
              ? `Registrering feilet`
              : "Registrer aktivitet"}
          </Fab>
        </Grid>
      </Grid>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={submitError || submitSuccess}
        onClose={handleCloseSnackbar}
        key={2}
      >
        <Alert
          severity={submitError ? "error" : submitSuccess ? "success" : ""}
        >
          {activitySubmitMessage}
        </Alert>
      </Snackbar>
    </form>
  );
}

export default ActivityForm;
