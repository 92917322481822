import React from "react";

import ShowTopBar from "../../components/Show/ShowTopBar";
import ShowActivities from "../../components/Show/ShowActivities";
import GridContentContainer from "../../layout/GridContentContainer";

function ActivitiesPage() {
  return (
    <GridContentContainer>
      <ShowTopBar title={"Alle tilgjengelige aktiviteter"} />
      <ShowActivities />
    </GridContentContainer>
  );
}

export default ActivitiesPage;
