import React, { useState } from "react";
import {
  TextField,
  Grid,
  Fab,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";

import { CloudUpload } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import activities from "../assets/data/activities.json";
import SelectMultiple from "../components/Input/SelectMultiple";

function ClubForm() {
  const [selectedValue, setSelectedValue] = useState([]);
  const [clubInfo, setClubInfo] = useState({
    clubName: "",
    contactPerson: "",
    contactEmail: "",
    contactPhone: "",
    activities: [],
    clubEmblem: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [clubSubmitMessage, setClubSubmitMessage] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const handleCloseSnackbar = () => {
    submitError ? setSubmitError(false) : setSubmitSuccess(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClubInfo({ ...clubInfo, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setClubInfo({ ...clubInfo, clubEmblem: file });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setClubSubmitMessage(
      "Kunne ikke legge til ny klubb. Funksjonalitet er i produksjon."
    );
    setTimeout(
      () => {
        setSubmitError(true);
        setIsLoading(false);
      },

      1000
    );
    //setClubSubmitMessage("La til ny klubb:", clubInfo.clubName);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} rowGap={1} width="40vw" maxWidth="500px">
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label="Organisasjonsnummer"
            name="orgNr"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Klubbnavn"
            name="clubName"
            value={clubInfo.clubName}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Kontaktperson"
            name="contactPerson"
            value={clubInfo.contactPerson}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Email-adresse"
            name="contactEmail"
            value={clubInfo.contactEmail}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Telefonnummer"
            name="contactPhone"
            value={clubInfo.contactPhone}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Adresse"
            name="address"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Postnummer"
            name="postNr"
          />
        </Grid>
        <Grid item xs={12}>
          <SelectMultiple
            list={activities}
            label="Aktiviteter"
            name="activities"
            value={selectedValue}
            setSelectedValue={setSelectedValue}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="clubEmblem"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="clubEmblem">
            <Fab
              variant="extended"
              component="span"
              color="primary"
              sx={{ minWidth: "100%" }}
            >
              <CloudUpload sx={{ mr: 1 }} />
              Last opp klubblogo
            </Fab>
          </label>
        </Grid>
        <Grid item xs={6}>
          <Fab
            type="submit"
            variant="extended"
            color={
              submitSuccess ? "success" : submitError ? "error" : "primary"
            }
            disabled={isLoading}
            sx={{ minWidth: "100%" }}
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            ) : submitError ? (
              <ErrorOutlineIcon sx={{ mr: 1 }} />
            ) : submitSuccess ? (
              <CheckCircleOutlineIcon sx={{ mr: 1 }} />
            ) : (
              <AddIcon sx={{ mr: 1 }} />
            )}
            {submitSuccess
              ? `${clubInfo.clubName} registrert`
              : submitError
              ? "Registrering feilet"
              : "Registrer klubb"}
          </Fab>
        </Grid>
      </Grid>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={submitError || submitSuccess}
        onClose={handleCloseSnackbar}
        key={3}
      >
        <Alert
          severity={submitError ? "error" : submitSuccess ? "success" : ""}
        >
          {clubSubmitMessage}
        </Alert>
      </Snackbar>
    </form>
  );
}

export default ClubForm;
