import React from "react";
import { Grid } from "@mui/material";
import activities from "../../assets/data/activities.json";
import FlipCard from "../ActivityCard/FlipCard";
import GridContentContainer from "../../layout/GridContentContainer";

function ShowClubActivities({ club }) {
  const clubActivities = activities.filter((activity) =>
    club.activities.includes(activity.name)
  );

  return (
    <GridContentContainer>
      {clubActivities.map((activity) => {
        return (
          <Grid item xs={clubActivities.length > 4 ? 2.8 : 3} key={activity.id}>
            <FlipCard activity={activity} club={club} />
          </Grid>
        );
      })}
    </GridContentContainer>
  );
}

export default ShowClubActivities;
