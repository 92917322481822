import "./App.css";
import { Route, Routes } from "react-router-dom";

import SideBar from "./layout/SideBar";

import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import SettingsPage from "./pages/SettingsPage";
import ActivitiesPage from "./pages/Activities/ActivitiesPage";
import ClubsPage from "./pages/ClubsPage";
import ClubPage from "./pages/ClubPage";
import AdminPage from "./pages/AdminPage";
import KostholdPage from "./pages/KostholdPage";
import CategoriesPage from "./pages/CategoriesPage";
import CategoryPage from "./pages/CategoryPage";
import FavoritesPage from "./pages/FavoritesPage";

import g from "./assets/palette/gradients.json";
import clubs from "./assets/data/clubs.json";
import activities from "./assets/data/activities.json";
import categories from "./assets/data/categories.json";
import useWindowDimensions from "./hooks/useWindowDimensions";

import { Box } from "@mui/material";
import Footer from "./layout/Footer";

function App() {
  const { width, height } = useWindowDimensions();
  const { iphone, small /*macbook, large*/ } = {
    iphone: 390,
    small: 750,
    //macbook: 1440,
    //large: 2000,
  };
  return (
    <>
      <SideBar />
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            flex: 1,
          }}
        >
          <div
            style={{
              padding: 20,
              height: width <= iphone ? "74vh" : "80vh",
              overflow: "auto",
              width: width <= iphone ? "79vw" : "84vw",
              border: "2px solid grey",

              borderRadius: 15,
              margin: "auto",
              background: g.newLightTransp,
              marginLeft:
                width <= iphone ? "3vw" : width <= small ? "3.7vw" : "8.7vw",
              marginTop: width <= iphone ? "2vh" : "4vh",
            }}
          >
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="kategorier" element={<CategoriesPage />} />
              {categories.map((category) => {
                return (
                  <Route
                    path={`kategorier/${category.name.toLowerCase()}`}
                    element={
                      category.name === "Kosthold" ? (
                        <KostholdPage />
                      ) : (
                        <CategoryPage category={category.name} />
                      )
                    }
                  />
                );
              })}
              {activities.map((activity) => {
                return (
                  <Route
                    path={`klubber/${activity.name.toLowerCase()}`}
                    element={<ClubsPage activity={activity.name} />}
                  />
                );
              })}
              <Route path="aktiviteter" element={<ActivitiesPage />} />
              <Route path="klubber" element={<ClubsPage />} />
              {clubs.map((club) => {
                return (
                  <Route
                    path={`klubber/${club.id}`}
                    element={<ClubPage club={club} />}
                  />
                );
              })}
              <Route path="kosthold" element={<KostholdPage />} />
              <Route path="favoritter" element={<FavoritesPage />} />
              <Route path="admin" element={<AdminPage />} />
              <Route path="profil" element={<ProfilePage />} />
              <Route path="innstillinger" element={<SettingsPage />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Box>
    </>
  );
}

export default App;
