import React, { useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";
import randomColor from "../../assets/palette/randomColor";
import clubs from "../../assets/data/clubs.json";
import FlipCard from "../ActivityCard/FlipCard";
import FrontCard from "../ActivityCard/FrontCard";
import { useNavigate } from "react-router-dom";
import GridContentContainer from "../../layout/GridContentContainer";

function ShowClubs({ activity }) {
  const navigate = useNavigate();

  const [colors, setColors] = useState([]);

  useEffect(() => {
    const generatedColors = clubs.map(() => randomColor());
    setColors(generatedColors);
  }, []);

  const mapActiveClubs = clubs.filter((club) =>
    club.activities.includes(activity)
  );

  const activeClubs = mapActiveClubs.map((club) => (
    <Grid item key={club.id}>
      <FlipCard club={club} />
    </Grid>
  ));

  const showAllClubs = clubs.map((club, index) => {
    return (
      <Grid item key={club.id} minHeight="100%">
        <FrontCard
          clubSize
          color={colors.at(index)}
          name={club.name}
          image={club.emblem}
          onClick={() => navigate(`/klubber/${club.id}`)}
        />
      </Grid>
    );
  });

  return (
    <GridContentContainer>
      {activity ? (
        activeClubs.length > 0 ? (
          activeClubs
        ) : (
          <Typography textAlign="center" color="lightgray">
            Fant ingen tilbydere av {activity.toLowerCase()}
          </Typography>
        )
      ) : (
        showAllClubs
      )}
    </GridContentContainer>
  );
}

export default ShowClubs;
