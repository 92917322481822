import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function ShowTopBar({ title, bottomMargin }) {
  let navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const IPHONE_WIDTH = 390;
  const SMALL_WIDTH = 750;

  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      marginBottom={width <= IPHONE_WIDTH ? 2 : bottomMargin ? bottomMargin : 8}
      position="sticky"
    >
      <Grid item>
        <IconButton
          onClick={() => navigate(-1)}
          style={{
            width: width > 2500 && height > 1900 ? 45 : 35,
            height: width > 2500 && height > 1900 ? 45 : 35,
          }}
        >
          <ArrowBackIosNewIcon style={{ height: "100%", width: "100%" }} />
        </IconButton>
      </Grid>
      <Grid item margin={"auto"}>
        <Typography
          fontSize={
            width <= IPHONE_WIDTH && title.length > 28
              ? 8
              : width <= IPHONE_WIDTH && title.length > 27
              ? 10
              : width <= IPHONE_WIDTH
              ? 13
              : width > SMALL_WIDTH
              ? 30
              : title.length > 27
              ? 14
              : width > 3000 && height > 2000
              ? 35
              : 20
          }
          fontWeight={"bold"}
          color={"black"}
        >
          {title}
        </Typography>
      </Grid>
      <IconButton
        onClick={() => navigate(1)}
        style={{
          width: width > 2500 && height > 1900 ? 45 : 35,
          height: width > 2500 && height > 1900 ? 45 : 35,
        }}
      >
        <ArrowForwardIosIcon style={{ height: "100%", width: "100%" }} />
      </IconButton>
    </Grid>
  );
}

export default ShowTopBar;
