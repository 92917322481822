import React, { useState } from "react";

import { Button } from "@mui/material";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function FooterButton({ label, onClick }) {
  const [hover, setHover] = useState(false);

  const { width, height } = useWindowDimensions();
  const SMALL_WIDTH = 750;
  const MACBOOK_WIDTH = 1440;

  return (
    <Button
      style={{
        width: "10vw",
        minWidth: 80,
        backgroundColor: "#d83a5b",
        height: hover ? "4vh" : "3vh",
        minHeight: 30,
        transitionDelay: "80ms",
        transitionProperty: "height",
        opacity: 0.95,
        marginRight: 10,
        borderRadius: 5,
        fontSize:
          width <= SMALL_WIDTH && label.length > 9
            ? 7
            : width <= SMALL_WIDTH
            ? 10
            : width <= MACBOOK_WIDTH && label.length > 9
            ? 9
            : width <= MACBOOK_WIDTH
            ? 12
            : width > 3000 && height > 2000 && 20,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      variant="contained"
      onClick={onClick}
    >
      {label}
    </Button>
  );
}

export default FooterButton;
