import { Grid } from "@mui/material";
import useWindowDimensions from "../hooks/useWindowDimensions";

function GridContentContainer({ children }) {
  const { width } = useWindowDimensions();

  const direction = width > 750 ? "row" : "column";

  return (
    <Grid
      container
      alignItems="center"
      direction={direction}
      justifyContent="space-evenly"
      padding={3}
      rowGap={3}
    >
      {children}
    </Grid>
  );
}

export default GridContentContainer;
