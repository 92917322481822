import React, { useState } from "react";

import { List, ListItem, Drawer, Divider, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";

import g from "../assets/palette/gradients.json";

import { useAuth } from "../context/AuthContext";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {
  ActivitiesButton,
  AdminButton,
  CategoriesButton,
  ClubsButton,
  FavoritesButton,
  HomeButton,
  KostholdButton,
  ProfileButton,
  SettingsButton,
} from "../components/Buttons";

function SideBar() {
  const [showSidebar, setShowSidebar] = useState(false);

  const { isAuthenticated } = useAuth();
  const { width } = useWindowDimensions();
  const SMALL_WIDTH = 750;

  const handleClick = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
      {!showSidebar && (
        <IconButton
          style={{ position: "absolute", zIndex: 1 }}
          size="large"
          onClick={handleClick}
          sx={{
            backgroundColor: "white",
            opacity: 0.8,
          }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        variant="permanent"
        sx={{
          "& .MuiDrawer-paper": {
            alignItems: "center",
            minWidth: "2vw",
            background: g.newLight,
            borderRight: "1px solid grey",
            justifyContent: "space-between",
            left: width <= SMALL_WIDTH && !showSidebar ? "-22vw" : 0,
            transitionDelay: "60ms",
            transitionProperty: "left",
          },
        }}
      >
        <List>
          {width <= SMALL_WIDTH && (
            <>
              <ListItem>
                <IconButton
                  onClick={handleClick}
                  style={{ width: "2vw", minWidth: 50 }}
                >
                  <ClearIcon style={{ width: "80%", height: "80%" }} />
                </IconButton>
              </ListItem>
              <Divider />
            </>
          )}

          <ListItem>
            <HomeButton onClick={width <= SMALL_WIDTH ? handleClick : false} />
          </ListItem>
          <ListItem>
            <CategoriesButton
              onClick={width <= SMALL_WIDTH ? handleClick : false}
            />
          </ListItem>
          <ListItem>
            <ActivitiesButton
              onClick={width <= SMALL_WIDTH ? handleClick : false}
            />
          </ListItem>
          <ListItem>
            <ClubsButton onClick={width <= SMALL_WIDTH ? handleClick : false} />
          </ListItem>
          <ListItem>
            <KostholdButton
              onClick={width <= SMALL_WIDTH ? handleClick : false}
            />
          </ListItem>
          <Divider sx={{ marginTop: 1 }} />
          <ListItem>
            <FavoritesButton
              onClick={width <= SMALL_WIDTH ? handleClick : false}
            />
          </ListItem>
        </List>
        <List>
          <Divider sx={{ marginBottom: 1 }} />
          {isAuthenticated && (
            <ListItem>
              <AdminButton
                onClick={width <= SMALL_WIDTH ? handleClick : false}
              />
            </ListItem>
          )}

          <ListItem>
            <ProfileButton
              onClick={width <= SMALL_WIDTH ? handleClick : false}
            />
          </ListItem>
          <ListItem>
            <SettingsButton
              onClick={width <= SMALL_WIDTH ? handleClick : false}
            />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

export default SideBar;
