import React from "react";
import { AuthProvider } from "./AuthContext";
import { FavProvider } from "./FavContext";

function RootProvider({ children }) {
  return (
    <AuthProvider>
      <FavProvider>{children}</FavProvider>
    </AuthProvider>
  );
}

export default RootProvider;
