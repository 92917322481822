import {
  Card,
  CardContent,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  CardActions,
  Tooltip,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import UndoIcon from "@mui/icons-material/Undo";
import FavoriteIcon from "@mui/icons-material/Favorite";
import GroupsIcon from "@mui/icons-material/Groups";

import { useFav } from "../../context/FavContext";

import g from "../../assets/palette/gradients.json";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function BackCard({ activity, club, color, onClick }) {
  let navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const SMALL_WIDTH = 750;
  const MACBOOK_WIDTH = 1440;

  const { addToFavorites, removeFromFavorites, isFavorite } = useFav();
  const self = {
    id: activity ? activity.id : "" + club.id,
    activity: activity ? activity : null,
    club,
  };

  const handleClickFav = (self) => {
    isFavorite(self) ? removeFromFavorites(self) : addToFavorites(self);
  };

  // Making the card color dark when liked, to better see the red in the liked icon
  const likedColor = g.newDark;

  return (
    <Card
      sx={{
        background: isFavorite(self) ? likedColor : color,
        height:
          width <= SMALL_WIDTH
            ? "34vh"
            : width < MACBOOK_WIDTH
            ? "28vh"
            : width > 3000 && height > 2000
            ? "39vh"
            : "42vh",
        width:
          width <= SMALL_WIDTH
            ? "48vw"
            : width < MACBOOK_WIDTH
            ? "20vw"
            : "15vw",

        marginTop:
          width > MACBOOK_WIDTH ? 8 : width > 3000 && height > 2000 && 12,
      }}
    >
      <CardActions>
        <Grid container position="relative" marginBottom={2}>
          <Grid item left={0} position="absolute">
            <IconButton onClick={onClick} style={{ marginLeft: 0 }}>
              <UndoIcon />
            </IconButton>
          </Grid>
          <Grid item margin="auto">
            <Tooltip title="Vis Klubb" placement="bottom">
              <IconButton onClick={() => navigate(`/klubber/${club.id}`)}>
                <GroupsIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item right={0} position="absolute">
            <IconButton
              onClick={() => handleClickFav(self)}
              style={{ color: isFavorite(self) ? "red" : null }}
            >
              <FavoriteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
      <CardContent>
        <TextField
          fullWidth
          margin="dense"
          id="tilbyder"
          defaultValue={club.contactPerson}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
          size="xs"
        />
        <TextField
          fullWidth
          margin="dense"
          size="small"
          id="mail"
          defaultValue={club.email}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        <TextField
          fullWidth
          id="nummer"
          margin="dense"
          defaultValue={club.phone}
          InputProps={{
            readOnly: true,
            inputMode: "numeric",
            startAdornment: (
              <InputAdornment position="start">
                <CallIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        <Typography
          style={{
            color: "white",
            fontWeight: "bold",
            position: "absolute",
            bottom: 10,
          }}
        >
          {club.name}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default BackCard;
